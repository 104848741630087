<template>
  <div class="tools01-abtest-report">
    <el-row type="flex" justify="space-between">
      <el-col :span="10">
        <p v-show="activeCount === 0" style="color: red">
          ABテスト未稼働のため通常フォーマットが配信中。
        </p>
      </el-col>
    </el-row>
    <el-row type="flex" justify="space-between" align="middle">
      <el-col>
        <el-button @click="detail(0)" type="primary">新規設定</el-button>
        <Question :placement="'right'" :content="'「新規設定」からABテストを新規で設定することができます。'" />
      </el-col>
      <el-col :span="5">
        <router-link :to="`/manual/${toolData.code2}?hash=abtest`">
          <UiButton2 type2="1" msg="マニュアル" />
        </router-link>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="width-max-cover">
          <el-table :data="abTestList">
            <el-table-column label="テスト名">
              <template slot-scope="scope">
                <span>{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="ステータス" width="120px">
              <template slot-scope="scope">
                <span>{{ scope.row.statusText }}</span>
              </template>
            </el-table-column>
            <el-table-column label="配信状態" width="120px">
              <template slot-scope="scope">
                <span v-if="scope.row.active === '0'">終了</span>
                <span v-else>配信中</span>
              </template>
            </el-table-column>
            <el-table-column label="テスト期間" width="230px">
              <template slot-scope="scope">
                <span>{{ scope.row.term }}</span>
              </template>
            </el-table-column>
            <el-table-column width="100px">
              <template slot-scope="scope">
                <div v-if="scope.row.active === '1' || scope.row.active === '2'" class="button-cover">
                  <el-button type="info" @click="stopTest(scope.row)">
                    <span v-if="scope.row.active === '1'">終了</span>
                    <span v-else-if="scope.row.active === '2'">停止</span>
                  </el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="120px">
              <template slot-scope="scope">
                <div class="button-cover">
                  <el-button type="success" @click="report(scope.row.testId)">レポート</el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="100px">
              <template slot-scope="scope">
                <div class="button-cover">
                  <el-button @click="detail(scope.row.testId)">確認</el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="100px">
              <template slot-scope="scope">
                <div class="button-cover">
                  <el-button type="warning" @click="copy(scope.row.testId, scope.row.name)">コピー</el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="100px">
              <template slot-scope="scope">
                <div class="button-cover">
                  <el-button type="danger" @click="del(scope.row.testId, scope.row.name)">削除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <h3>用語説明</h3>
        <table class="button-description">
          <!-- <tr>
            <th><el-button type="info">停止</el-button></th>
            <td>ABテスト終了後に「A側を配信」と設定してある場合、A側の配信を「停止」します。停止後は、設定済の通常フォーマットが配信されます。</td>
          </tr> -->
          <tr>
            <th><el-button type="info">終了</el-button></th>
            <td>設定したテスト期間が終了する前に、実行中のABテストを終了します。</td>
          </tr>
          <tr>
            <th><el-button class="report" type="success">レポート</el-button></th>
            <td>ABテスト結果を確認することができます。</td>
          </tr>
          <tr>
            <th><el-button>確認</el-button></th>
            <td>設定したABテストの内容を確認・編集できます。</td>
          </tr>
          <tr>
            <th><el-button type="warning">コピー</el-button></th>
            <td>ABテストの内容をコピーして、新しいABテストを設定することができます。</td>
          </tr>
          <tr>
            <th><el-button type="danger">削除</el-button></th>
            <td>ABテスト結果および設定したABテストの内容を削除します。</td>
          </tr>
        </table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Question from "@/components/Ui/Question.vue";
import UiButton2 from "@/components/Ui/Button2.vue";

export default {
  components: {
    Question,
    UiButton2,
  },
  props: {
    toolId: String,
    pageId: String,
  },
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapGetters(["rt"]),
    toolData() {
      return this.$store.getters["toolData"](this.toolId);
    },
    abTestList() {
      return this.$store.getters["tools01/abTestListBySomeIds"](
        this.toolId,
        this.pageId
      );
    },
    activeCount() {
      let activeCount = 0;
      for (const abTestData of this.abTestList) {
        if (abTestData.active === "1" || abTestData.active === "2") {
          activeCount++;
        } else {
          // no action.
        }
      }

      //
      return activeCount;
    },
  },
  methods: {
    stopTest(testData) {
      let message = ``;
      if (testData.active === "1") {
        message = `「${testData.name}」の配信を現在の設定に従って終了します。よろしいですか？`;
      } else if (testData.active === "2") {
        message = `「${testData.name}」の配信を停止します。よろしいですか？`;
      } else {
        alert(`Unexpected 'active'. -> ${testData.active}`);
        return;
      }
      if (confirm(message)) {
        let params = new URLSearchParams();
        params.append("tool", this.toolData.code2);
        params.append("page_id", this.pageId);
        params.append("test_id", testData.testId);
        this.$axios
          .post(`${this.rt}/api/tools01/stopTest.php`, params)
          .then((response) => {
            console.log(response);
            this.$store.dispatch("tools01/setAbTestList");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    report(testId) {
      this.$router.push(
        `/tools01/${this.toolData.code2}/abtest-report/${this.pageId}/${testId}`
      );
    },
    copy(testId, name) {
      if (
        confirm(
          `「${name}」の内容をコピーして新しくテストを作成します。よろしいですか？`
        )
      ) {
        let params = new URLSearchParams();
        params.append("tool", this.toolData.code2);
        params.append("page_id", this.pageId);
        params.append("test_id", testId);
        this.$axios
          .post(`${this.rt}/api/tools01/copyTest.php`, params)
          .then((response) => {
            console.log(response);
            this.$store.dispatch["tools01/setAbTestList"];
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    detail(testId) {
      this.$router.push(
        `/tools01/${this.toolData.code2}/abtest-detail/${this.pageId}/${testId}`
      );
    },
    del(testId, name) {
      if (confirm(`「${name}」を削除します。よろしいですか？`)) {
        let params = new URLSearchParams();
        params.append("tool", this.toolData.code2);
        params.append("page_id", this.pageId);
        params.append("test_id", testId);
        this.$axios
          .post(`${this.rt}/api/tools01/delTest.php`, params)
          .then((response) => {
            console.log(response);
            this.$store.dispatch["tools01/setAbTestList"];
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    // stop(testId, name, status) {
    //   var msg;
    //   if (status === "1") {
    //     msg = `「${name}」の配信を現在の設定に従って終了します。よろしいですか？`;
    //   } else {
    //     msg = `「${name}」の配信を停止します。よろしいですか？`;
    //   }
    //   if (confirm(msg)) {
    //     let params = new URLSearchParams();
    //     params.append("tool", this.toolData.code2);
    //     params.append("page_id", this.pageId);
    //     params.append("test_id", testId);
    //     this.$axios
    //       .post(`${this.rt}/api/tools01/stopTest.php`, params)
    //       .then((response) => {
    //         console.log(response);
    //         this.$store.dispatch["tools01/setAbTestList"];
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.button-cover {
  text-align: center;
}

table {
  &.button-description {
    tr {
      th {
        text-align: left;
      }

      td {
        height: 3em;
        padding: 0 1em;
      }
    }
  }
}
</style>
