<template>
  <div class="tools02-parameter-log-log-table">
    <!-- ------------------- ↓↓ 検索部分 ↓↓ ------------------- -->
    <div class="log-search-box">
      <el-row type="flex" align="middle">
        <el-col>
          <el-button @click="conditionData.datetimeRange = g_getDatetimeRange('30')"
            type="primary">全件（過去２０日）</el-button>
          <el-button @click="conditionData.datetimeRange = g_getDatetimeRange('today')" type="primary">本日</el-button>
          <el-button @click="
            conditionData.datetimeRange = g_getDatetimeRange('yesterday')
            " type="primary">昨日</el-button>
        </el-col>
      </el-row>
      <el-row v-if="type === 'ctrl'" type="flex" align="middle">
        <el-col :span="3">
          <span>ユーザーID: </span>
        </el-col>
        <el-col :span="3">
          <el-input-number v-model="conditionData.userId" :min="0"></el-input-number>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :xs="5" :sm="3">検索日時：</el-col>
        <el-col :xs="18" :sm="20">
          <el-date-picker v-model="conditionData.datetimeRange" type="datetimerange" align="right"
            start-placeholder="開始日時" end-placeholder="終了日" value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']" clearable>
          </el-date-picker></el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :xs="5" :sm="3">リファラ：</el-col>
        <el-col :xs="18" :sm="20"><el-input placeholder="空白で検索対象外となります" v-model="conditionData.referrer"
            clearable></el-input></el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :xs="5" :sm="3">飛び先URL：</el-col>
        <el-col :xs="18" :sm="20"><el-input placeholder="空白で検索対象外となります" v-model="conditionData.completeLink"
            clearable></el-input></el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :xs="5" :sm="3">ページタイプ：</el-col>
        <el-col :xs="18" :sm="20">
          <el-select v-model="conditionData.logType" clearable placeholder="選択してください。">
            <el-option v-for="logTypeData in parameterPassingLogTypeList" :key="logTypeData.id"
              :label="logTypeData.name" :value="logTypeData.id"></el-option></el-select></el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :xs="5" :sm="3">媒体：</el-col>
        <el-col :xs="18" :sm="20"><el-select v-model="conditionData.toolId" placeholder="選択してください。" clearable>
            <el-option v-for="toolData in toolListForSearch" :key="toolData.toolId" :label="toolData.mediaName"
              :value="toolData.toolId"></el-option></el-select></el-col>
      </el-row>
      <el-row>
        <el-col style="text-align: center">
          <el-button @click="getParameterPassingLogList('default', 1)" type="primary">検索</el-button>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row type="flex" v-if="searchedDatetimeRange.length > 0">
        <el-col>検索期間:
          <span>{{ searchedDatetimeRange[0] }} ～<br class="sp-only" />
            {{ searchedDatetimeRange[1] }}</span></el-col>
      </el-row>
    </div>
    <!-- ------------------- ↑↑ 検索部分ここまで ↑↑ ------------------- -->

    <div v-if="loading === true" v-loading="loading" style="width: 100%; height: 150px"></div>
    <div v-else class="width-max-cover">
      <div style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        ">
        <el-pagination :page-size="pageSize" :pager-count="pagerCount" :total="totalCount"
          :current-page.sync="currentPage" layout="prev, pager, next" />
        <div style="padding: 5px">
          <span style="margin-right: 15px">
            件数：
            <span>{{ totalCount | localeNum }}</span>件
          </span>
          <el-button @click="getParameterPassingLogList('csv', 1)" type="success">CSVダウンロード</el-button>
        </div>
      </div>
      <el-table :data="parameterPassingLogList" border>
        <el-table-column v-if="type === 'ctrl'" prop="userId" label="userId" width="70px">
        </el-table-column>
        <el-table-column label="媒体" width="65px">
          <template slot-scope="scope">
            <span>{{
              $store.getters.toolData(scope.row.toolId).alphaCode
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="日時" width="110px">
          <template slot-scope="scope">
            <span v-html="scope.row.formattedCreateDatetimeWithBr"></span>
          </template>
        </el-table-column>
        <el-table-column label="リファラ" prop="referrer"> </el-table-column>
        <el-table-column label="ページタイプ" width="119px">
          <template slot-scope="scope">
            <span>{{
              $store.getters["tools02/parameterPassingLogTypeData"](
                scope.row.pageType
              ).name
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="飛び先URL" prop="completeLink">
        </el-table-column><el-table-column v-if="type === 'ctrl'" label="削除" width="90px">
          <template slot-scope="scope">
            <div style="text-align: center">
              <el-button type="danger" @click="deleteLog(scope.row)" size="mini">削除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :page-size="pageSize" :pager-count="pagerCount" :total="totalCount"
        :current-page.sync="currentPage" layout="prev, pager, next" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "tools02-parameter-log-log-table",
  components: {},
  props: {
    type: String,
  },
  data() {
    return {
      parameterPassingLogList: [],
      // pageSize: 2,
      pageSize: 100,
      pagerCount: 7,
      currentPage: 1,
      logsTotal: 0,

      // ===== ↓↓ 検索用 ↓↓ =====
      conditionData: {
        userId: "",
        toolId: "",
        referrer: "",
        completeLink: "",
        logType: "",
        datetimeRange: [],
      },
      // ===== ↑↑ 検索用 ↑↑ =====

      searchedDatetimeRange: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "tools", "aspNames"]),
    ...mapGetters("tools02", ["parameterPassingLogTypeList"]),
    toolListForSearch() {
      let toolList = JSON.parse(
        JSON.stringify(this.$store.getters["tools02/toolListInTopPageOrder"])
      );

      //
      const otherToolData = {
        toolId: "999",
        mediaName: "その他",
      };
      toolList.push(otherToolData);

      //
      return toolList;
    },
  },
  watch: {
    currentPage: {
      handler(currentPage) {
        this.getParameterPassingLogList("default", currentPage);
      },
      immediate: true,
    },
  },
  methods: {
    getParameterPassingLogList(mode, currentPage) {
      this.currentPage = currentPage;
      this.loading = true;

      //
      const userId =
        this.conditionData.userId === "" ? "0" : this.conditionData.userId;
      const toolId =
        this.conditionData.toolId === "" ? "0" : this.conditionData.toolId;
      let datetimeRange = JSON.stringify(this.conditionData.datetimeRange);
      let referrer = encodeURIComponent(this.conditionData.referrer);
      let completeLink = encodeURIComponent(this.conditionData.completeLink);
      let logType =
        this.conditionData.logType === "" ? "0" : this.conditionData.logType;

      //
      var url = `${this.rt}/l/api/tools02/parameter-passing/get-log-list`;
      url += `?type=${this.type}`;
      url += `&mode=${mode}`;
      url += `&user_id=${userId}`;
      url += `&tool_id=${toolId}`;
      url += `&datetime_range=${datetimeRange}`;
      url += `&referrer=${referrer}`;
      url += `&complete_link=${completeLink}`;
      url += `&log_type=${logType}`;
      url += `&current_page=${currentPage}`;
      url += `&page_size=${this.pageSize}`;
      this.$axios
        .get(url)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            if (mode === "default") {
              this.parameterPassingLogList = response.data.list;
              this.totalCount = response.data.totalCount;
              this.searchedDatetimeRange = response.data.searchedDatetimeRange;
            } else if (mode === "csv") {
              location.href = `${this.rt}/l/api/file/download`;
            } else {
              console.log("???");
            }
          } else if (response.data.status === 400) {
            alert(response.data.message);
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteLog(data) {
      //
      let message = "";
      message += `userId: ${data.userId}\n`;
      message += `媒体: ${this.$store.getters.toolData(data.toolId).alphaCode}\n`;
      message += `日時: ${data.formattedCreateDatetime}\n`;
      message += `リファラ: ${data.referrer}\n`;
      message += `ページタイプ: ${this.$store.getters["tools02/parameterPassingLogTypeData"](
        data.pageType
      ).name
        }\n`;
      message += `飛び先URL: ${data.completeLink}\n`;
      message += `\n`;
      message += `このログを削除します。よろしいですか？\n`;
      if (confirm(message)) {
        //
      } else {
        return;
      }

      //
      let params = new URLSearchParams();
      params.append("id", data.id);
      this.$axios
        .post(`${this.rt}/l/api/tools02/parameter-passing/delete-log`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            alert(response.data.message);
            this.getParameterPassingLogList("default", 1);
          } else if (response.data.status === 400) {
            alert(response.data.message);
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
