<template>
  <div v-if="tool.codeKebab != 'cart-system'" class="tools02-home-auto-import-tags">
    <el-row class="description">
      <el-col>
        <p style="margin-bottom: 30px">
          ASP各社が提供するポイントバック通知機能を、ASPに依頼してASPごとに設定していただくことで、ASPの成果情報がアドタスカルへ自動でインポートされるようになります。以後、アドタスカルへの手動での成果情報のインポート作業が不要になります。ASP各社が提供するポイントバック通知機能を利用するために、対象のASPのご担当者様に通知URLをご連絡ください。<br />
          チャットワークにてご連絡する場合は、「通知URLをコピー（Chatwork用）」をお送りください。
        </p>
        <div>ASP担当者へ連絡する際の文面の例</div>
        <div style="box-sizing: border-box; border: solid 1px #aaa; padding: 10px">
          アドタスカルの成果連携ツールを使うため、ポイントバック通知機能の設定をお願いします。通知URLは以下のものを設定してください。<br />⇒
          （各ASPごとの通知URL）
        </div>
        <div class="important">
          <h4>通知URLと自動連携に関する重要事項</h4>
          <p>
            通知URLは、アドタスカルの全広告成果連携ツール（Google広告成果連携ツール、Yahoo!検索広告成果連携ツール、Meta広告コンバージョンAPI連携ツール、Microsoft広告成果連携ツール）で共通です。
          </p>
          <p>
            ASPによって、自動連携の設定を一度したら「他の案件を扱う場合に再連携が不要なASP」と、「他の案件を扱う場合に再連携が必要なASP」があります。以下の「ASP名/自動連携の設定単位」をご参照ください。<br />
            ・ASPアカウント単位：一度設定したら、他の案件を行う際も連携設定は不要<br />
            ・ASP登録サイト単位：登録しているサイトごとに連携が必要。別サイトの場合は再連携が必要<br />
            ・メディア単位：登録しているメディアごとに連携が必要。別メディアの場合は再連携が必要<br />
            ・案件単位：案件ごとに連携が必要。別案件を行う場合は再連携が必要<br />
            ・アフィリコード単位：アフィリコードごとに連携が必要。別のアフィリコードで連携する場合は再連携が必要
          </p>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <div class="import-codes-cover">
          <table class="import-codes">
            <thead>
              <tr>
                <th colspan="2">
                  <div>ASP名／自動連携の設定単位</div>
                </th>
                <th class="empty">
                  <div></div>
                </th>
                <th colspan="3">
                  <div>通知URL</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="autoImportCode in autoImportCodes" :key="autoImportCode.aspId">
                <template v-if="autoImportCode.isCartSystem === '0'">
                  <td class="asp-name">
                    <div>{{ autoImportCode.aspName }}</div>
                    <div class="pointback-setting">
                      <template v-if="autoImportCode.aspId === '1'">
                        <span>ASPアカウント単位<br>or<br>ASP登録サイト単位</span>
                      </template>
                      <template v-else>
                        <span v-if="autoImportCode.pointbackSetting === '0'">ASPアカウント</span>
                        <span v-else-if="autoImportCode.pointbackSetting === '1'">ASP登録サイト</span>
                        <span v-else-if="autoImportCode.pointbackSetting === '2'">案件</span>
                        <span v-else-if="autoImportCode.pointbackSetting === '3'">アフィリコード</span>
                        <span v-else-if="autoImportCode.pointbackSetting === '4'">メディア</span>
                        <span>単位</span>
                      </template>
                    </div>
                  </td>
                  <td style="vertical-align: middle">
                    <div class="logo">
                      <img v-if="autoImportCode.logoExists === true" style="margin: 0"
                        :src="`${rt}/img/asp-logos/${autoImportCode.aspCode}-logo.jpg`" alt="" />
                      <img v-else :src="`${rt}/img/nophoto.png`" alt="nophoto" />
                    </div>
                  </td>
                  <td></td>
                  <td style="vertical-align: middle">
                    <div class="import-code">
                      <div v-if="autoImportCode.aspId === '23' ||
                        autoImportCode.aspId === '26'
                      ">
                        <p style="text-align: center">AFAD移行のため使用不可</p>
                      </div>
                      <div v-else-if="autoImportCode.aspId === '75' || autoImportCode.aspId === '103'">
                        <p style="text-align: center">個別の通知URLはありません。詳細はマニュアルをご確認ください。</p>
                      </div>
                      <div v-else-if="
                        autoImportCode.aspId === '41' &&
                        userData.valueCommerceReleaseDatetime === null
                      ">
                        <p style="text-align: center">事前確認が必要です。詳細はマニュアルをご確認お願いいたします。</p>
                      </div>
                      <el-input v-else type="textarea" :autosize="{ minRows: 1 }" :value="autoImportCode.autoImportCode"
                        onclick="this.select();" />
                    </div>
                  </td>
                  <td class="copy-column">
                    <div class="copy-btn">
                      <div v-if="autoImportCode.aspId === '23' ||
                        autoImportCode.aspId === '26' ||
                        autoImportCode.aspId === '41' ||
                        autoImportCode.aspId === '103'
                      ">
                      </div>
                      <el-button v-else @click="copyLink(autoImportCode)"
                        type="primary">通知URLをコピー<br />（Chatwork用）</el-button>
                    </div>
                  </td>
                  <td class="manual-column">
                    <div class="manual-btn">
                      <a v-if="autoImportCode.aspPointBackManual.match(
                        /(http|https):\/\/.+/
                      )
                      " :href="autoImportCode.aspPointBackManual" rel="noopener noreferrer" target="_blank">
                        <el-button v-show="autoImportCode.aspPointBackManual != ''" type="primary">マニュアル</el-button>
                      </a>
                      <router-link v-else :to="autoImportCode.aspPointBackManual">
                        <el-button v-show="autoImportCode.aspPointBackManual != ''" type="primary">マニュアル</el-button>
                      </router-link>
                    </div>
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="margin-top: 20px">
          <div>
            ※注１ 事前に<router-link
              to="/tools02/google-cv/accounts">Google広告アカウント・プログラム情報の登録</router-link>が完了していない場合、成果情報が登録されませんのでご注意ください。
          </div>
          <div>※注２ 通知URLは変更しないようにしてください。</div>
        </div>
      </el-col>
    </el-row>
    <FAQ :faqList="faqList" />
  </div>
  <div v-else class="tools02-home-auto-import-tags">
    <el-row>
      <el-col>
        <el-row>
          <el-col>
            <h3 class="back-gray">
              カート・計測システム（※ご利用には契約が必要）
            </h3>
            <div style="margin-top: 20px">
              <div>
                カートシステムや計測システムが提供するキックバック機能やソケット連携機能を設定していただくことで、設定したシステムの成果情報がアドタスカルへ自動でインポートされるようになります。
              </div>
              <div>
                ポイントバック通知機能の設定方法は、それぞれのシステムのマニュアルにてご確認をお願いします。
              </div>
            </div>
            <div class="important">
              <h4>通知URLと自動連携に関する重要事項</h4>
              <p>
                通知URLは、アドタスカルの全広告成果連携ツール（Google広告成果連携ツール、Yahoo!検索広告成果連携ツール、Meta広告コンバージョンAPI連携ツール）で共通です。
              </p>
              <p>
                カート・計測システムによって、自動連携の設定を一度したら「他の案件を扱う場合に再連携が不要なシステム」と、「他の案件を扱う場合に再連携が必要なシステム」があります。以下の「カート・計測システム/自動連携の設定単位」をご参照ください。<br />
                ・ASPアカウント単位：一度設定したら、他の案件を行う際も連携設定は不要<br />
              </p>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <div class="import-codes-cover">
              <table class="import-codes">
                <thead>
                  <tr>
                    <th colspan="2">
                      <div>カート・計測システム名／自動連携の設定単位</div>
                    </th>
                    <th class="empty">
                      <div></div>
                    </th>
                    <th colspan="3">
                      <div>通知URL</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="cartAutoImportCodeData in cartAutoImportCodes" :key="cartAutoImportCodeData.aspId">
                    <td class="asp-name">
                      <div>{{ cartAutoImportCodeData.aspName }}</div>
                      <div class="pointback-setting">
                        <span v-if="cartAutoImportCodeData.pointbackSetting === '0'">ASPアカウント</span>
                        <span v-else-if="cartAutoImportCodeData.pointbackSetting === '1'
                        ">ASP登録サイト</span>
                        <span v-else-if="cartAutoImportCodeData.pointbackSetting === '2'
                        ">案件</span>
                        <span v-else-if="cartAutoImportCodeData.pointbackSetting === '3'
                        ">アフィリコード</span>
                        <span>単位</span>
                      </div>
                    </td>
                    <td style="vertical-align: middle">
                      <div class="logo">
                        <img v-if="cartAutoImportCodeData.logoExists === true" style="margin: 0"
                          :src="`${rt}/img/asp-logos/${cartAutoImportCodeData.aspCode}-logo.jpg`" alt="" />
                        <img v-else :src="`${rt}/img/nophoto.png`" alt="nophoto" />
                      </div>
                    </td>
                    <td></td>

                    <template v-if="activeCartSystemSubscriptionData !== undefined &&
                      (activeCartSystemSubscriptionData.cartSystemProductId ===
                        '2' ||
                        activeCartSystemSubscriptionData.aspId ===
                        cartAutoImportCodeData.aspId)
                    ">
                      <td style="vertical-align: middle">
                        <div class="import-code">
                          <el-input type="textarea" :autosize="{ minRows: 1 }"
                            :value="cartAutoImportCodeData.autoImportCode" onclick="this.select();" />
                        </div>
                      </td>
                      <td class="copy-column">
                        <div class="copy-btn">
                          <el-button @click="
                            copyLink(cartAutoImportCodeData)
                            " type="primary">通知URLをコピー<br />（Chatwork用）</el-button>
                        </div>
                      </td>
                    </template>
                    <template v-else>
                      <td colspan="2">
                        <div class="import-code">
                          {{
                            cartAutoImportCodeData.aspName
                          }}連携は別途のお申し込みが必要です。
                        </div>
                      </td>
                    </template>
                    <td class="manual-column">
                      <div class="manual-btn" v-if="activeCartSystemSubscriptionData !== undefined &&
                        (activeCartSystemSubscriptionData.cartSystemProductId ===
                          '2' ||
                          activeCartSystemSubscriptionData.aspId ===
                          cartAutoImportCodeData.aspId)
                      ">
                        <a :href="cartAutoImportCodeData.aspPointBackManual" rel="noopener noreferrer" target="_blank">
                          <el-button v-show="cartAutoImportCodeData.aspPointBackManual != ''
                            " type="primary">マニュアル</el-button>
                        </a>
                      </div>
                      <div v-else class="manual-btn">
                        <router-link :to="'/tools02/cart-system/' +
                          cartAutoImportCodeData.aspId
                          ">
                          <el-button v-if="activeCartSystemSubscriptionData === undefined &&
                            applyingCartSystemSubscriptionData !==
                            undefined &&
                            (applyingCartSystemSubscriptionData.cartSystemProductId ===
                              '2' ||
                              applyingCartSystemSubscriptionData.aspId ===
                              cartAutoImportCodeData.aspId)
                          " type="primary">申込中</el-button>
                          <el-button v-else type="primary">申し込む</el-button>
                        </router-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style="margin-top: 20px">
              <div>
                ※注１ 事前に<router-link
                  to="/tools02/google-cv/accounts">Google広告アカウント・プログラム情報の登録</router-link>が完了していない場合、成果情報が登録されませんのでご注意ください。
              </div>
              <div>※注２ 通知URLは変更しないようにしてください。</div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FAQ from "@/components/Ui/FAQ.vue";

export default {
  name: "Tools02HomeAutoImportTags",
  components: {
    FAQ,
  },
  props: {},
  data() {
    return {
      faqList: [
        {
          title: "ASP自動連携についてよくある質問（全ツール共通）",
          url: "https://docs.google.com/spreadsheets/d/1qJYOSIeLsthboFSx1sQaTxZMmFuvKSPstgx-Q6M6SWw/edit#gid=1348359011",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "userData", "apiErrorMessage"]),
    ...mapGetters("tools02", [
      "cartAutoImportCodes",
      "activeCartSystemSubscriptionData",
      "applyingCartSystemSubscriptionData",
    ]),
    autoImportCodes() {
      let aspDataList = this.$store.getters["tools02/autoImportCodes"];
      let newAspDataList = [];
      let loopIndex = 0;
      let famData = {};
      let famAdIndex = 0;
      aspDataList.forEach((aspData) => {
        if (aspData.aspId === "81") {
          // famのデータを保持
          famData = aspData;
        } else {
          if (aspData.aspId === "7") {
            // famADのindexを記録
            famAdIndex = loopIndex;
          } else {
            //
          }
          newAspDataList.push(aspData);
        }
        loopIndex++;
      });

      //
      newAspDataList.splice(famAdIndex + 1, 0, famData);

      //
      return newAspDataList;
    },
  },
  watch: {},
  methods: {
    copyLink(aspData) {
      // 
      let copyText = `[code]${aspData.autoImportCode}[/code]`;
      if (
        aspData.aspId === '108' ||
        aspData.aspId === '109'
      ) {
        copyText = `${aspData.aspName}用\n` + copyText;
      } else {
        // no action.
      }

      // 
      setTimeout(async () => {
        await navigator.clipboard.writeText(copyText);
        alert("コピーしました。");
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row.description {
  .important {
    margin-top: 30px;

    h4 {
      margin-bottom: 0;
    }

    p {
      margin: 10px 0;
    }
  }
}

table.import-codes {
  border-collapse: collapse;
  width: 100%;

  thead {
    tr {
      th {
        font-size: 16px;
        color: white;
        border-bottom: 1px solid #ccc;

        &.empty {
          border: none;
          width: 1%;
        }

        div {
          background: #808080;
          margin-bottom: 5px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 15px 0;
        // border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;

        &.asp-name {
          width: 20%;
          font-size: 16px;
          font-weight: bold;

          @media screen and (max-width: 767px) {
            font-size: 12px !important;
          }

          .pointback-setting {
            font-size: 18px;
            font-weight: normal;
            color: #999;

            @media screen and (max-width: 767px) {
              font-size: 12px !important;
            }
          }
        }

        div {
          &.logo {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            img {
              width: 242px;
              margin: 10px 0;
              border: 1px solid #ccc;
            }
          }

          &.import-code {
            padding-right: 10px;

            :deep(.el-textarea) {
              textarea {
                height: 90px !important;
              }
            }
          }
        }

        &.copy-column {
          width: 14%;

          .copy-btn {
            text-align: center;

            .el-button {
              font-size: 14px;
              background-color: #59c789;
              border-color: #4fc683;
              padding: 0;
              width: 100%;
              height: 100px;

              @media screen and (max-width: 767px) {
                font-size: 12px !important;
              }
            }
          }
        }

        &.manual-column {
          width: 14%;
          padding-left: 1%;

          .manual-btn {
            text-align: center;

            .el-button {
              font-size: 14px;
              background-color: #1c6db5;
              padding: 0;
              width: 100%;
              height: 100px;

              @media screen and (max-width: 767px) {
                font-size: 12px !important;
              }
            }
          }
        }
      }
    }
  }
}

.import-codes-cover {
  overflow: auto;

  .import-codes {
    width: 1000px;
  }
}
</style>
