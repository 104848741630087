<template>
  <div class="tools01-abtest-detail" v-if="componentStart == true">
    <el-row v-if="testId != 0" type="flex" justify="end">
      <el-col :span="3" class="right-button-cover">
        <el-button @click="report" type="success">レポート</el-button>
      </el-col>
      <el-col :span="3" class="right-button-cover">
        <el-button @click="del" type="danger">削除</el-button>
      </el-col>
    </el-row>

    <!-- ↓テスト情報↓ -->
    <el-row>
      <el-col>
        <el-descriptions
          class="margin-top"
          :title="pageData.name"
          :column="1"
          border
        >
          <el-descriptions-item>
            <template slot="label">
              テスト名<Question
                :placement="'left'"
                :content="'実施するABテスト名を任意で設定してください。'"
              />
            </template>
            <el-input v-model="ABTestConfigData.name"></el-input>
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label">
              テスト終了時の処理<Question
                :placement="'left'"
                :content="'「テスト期間終了」および「終了条件回数達成」による<br>ABテスト終了時の処理を指定できます。'"
              />
            </template>
            <span v-if="ABTestConfigData.status[0] === 2">{{
              after_list[ABTestConfigData.after].name
            }}</span>
            <el-select
              v-else
              v-model="ABTestConfigData.after"
              placeholder="選択"
            >
              <el-option
                v-for="after in after_list"
                :key="after.id"
                :label="after.name"
                :value="after.id"
              ></el-option>
            </el-select>
          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label">テスト期間</template>
            <template v-if="ABTestConfigData.status[0] == 0">
              <el-date-picker
                v-model="ABTestConfigData.date"
                type="daterange"
                align="right"
                start-placeholder="Start Date"
                end-placeholder="End Date"
                value-format="yyyy-MM-dd"
              />
            </template>
            <template v-else-if="ABTestConfigData.status[0] == 1">
              <span>{{ ABTestConfigData.date[0] }}</span>
              <span>～</span>
              <el-date-picker
                v-model="ABTestConfigData.date[1]"
                align="right"
                value-format="yyyy-MM-dd"
              />
            </template>
            <template v-else-if="ABTestConfigData.status[0] == 2">
              <span>{{ ABTestConfigData.date[0] }}</span>
              <span>～</span>
              <span>{{ ABTestConfigData.date[1] }}</span>
            </template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              終了条件回数<Question
                :placement="'left'"
                :content="'終了条件回数で設定した値に、テスト回数が達したら、<br>テストを終了させることができます。<br>未設定の場合は、回数によるテスト終了をしません。'"
              />
            </template>
            <span v-if="ABTestConfigData.status[0] === 2"
              >{{ ABTestConfigData.amount_limit | localeNum }}回</span
            >
            <!-- <el-input
              v-else
              v-model="ABTestConfigData.amount_limit"
              type="number"
              min="0"
            >
              <template slot="prepend">終了条件回数</template>
            </el-input> -->
            <el-input
              v-else
              v-model="ABTestConfigData.amount_limit"
              style="width: 150px"
              type="number"
              min="0"
            ></el-input>
          </el-descriptions-item>
          <template v-if="toolData.code2 === 'popup01'">
            <el-descriptions-item>
              <template slot="label">
                表示分割領域<Question
                  :placement="'left'"
                  :content="`同一ページ内での、ユーザーの到達位置によって、<br>表示する${toolData.name}を変更することができます。<br>最大でページを3分割することができ、<br>その表示分割位置によって表示する${toolData.name}を変更することができます。`"
                />
              </template>
              <el-select
                v-if="ABTestConfigData.status[0] == 0"
                v-model="ABTestConfigData.sections"
                placeholder="選択"
              >
                <el-option
                  v-for="sections in split_list"
                  :key="sections"
                  :label="sections"
                  :value="sections"
                ></el-option>
              </el-select>
              <span v-else>{{ ABTestConfigData.sections }}</span>
            </el-descriptions-item>
            <el-descriptions-item v-if="ABTestConfigData.sections >= 2">
              <template slot="label">
                <Question
                  :placement="'left'"
                  :content="`「ページを訪問した訪問者が到達した最下部の位置を判別して${toolData.name}を表示する」<br>を選択した場合、ユーザーが分割領域3の位置まで到達し、その後、<br>分割領域1の位置まで戻って離脱したとしても、分割領域3に設定した${toolData.name}を表示します。<br><br>「離脱時に訪問者が滞在している位置を判別して${toolData.name}を表示する」<br>を選択した場合、ユーザーが分割領域3の位置まで到達し、その後、<br>分割領域1の位置まで戻って離脱すると、分割領域1に設定した${toolData.name}を表示します。`"
                />
              </template>
              <el-radio-group
                v-model="ABTestConfigData.position"
                :disabled="ABTestConfigData.status[0] !== 0"
              >
                <div>
                  <el-radio :label="0"
                    >ページを訪問した訪問者が到達した最下部の位置を判別して{{
                      toolData.name
                    }}を表示する</el-radio
                  >
                </div>
                <div>
                  <el-radio :label="1"
                    >離脱時に訪問者が滞在している位置を判別して{{
                      toolData.name
                    }}を表示する</el-radio
                  >
                </div>
              </el-radio-group>
            </el-descriptions-item>
          </template>
        </el-descriptions>
      </el-col>
    </el-row>
    <!-- ↑テスト情報↑ -->

    <!-- ↓フォーマット登録部分↓ -->
    <el-row :gutter="30">
      <div
        v-for="(format, index) in ABTestConfigData.format"
        :key="index"
        v-show="
          (ABTestConfigData.sections >= 1 && (index == 0 || index == 1)) ||
          (ABTestConfigData.sections >= 2 && (index == 2 || index == 3)) ||
          (ABTestConfigData.sections >= 3 && (index == 4 || index == 5))
        "
      >
        <el-col :span="12">
          <el-checkbox
            v-model="format.active"
            :disabled="ABTestConfigData.status[0] != 0"
            >配信する<Question
              :placement="'top'"
              :content="`「配信する」を選択すると、設定した${toolData.name}が表示されます。「配信する」を選択していない状態では、${toolData.name}は表示されません。`"
          /></el-checkbox>
          <FormatRegister
            :type="1"
            :toolId="toolData.toolId"
            :pageId="pageId"
            :format="format.formatDetail"
            :status="ABTestConfigData.status[0]"
            :area="index"
            :active="format.active"
            @formatDecide="formatDecide"
            :parentComponentIsEditing="ABTestConfigDataIsEditing"
            @registerParentConfigData="reg()"
          />
          <h4>{{ toolData.name }}からの飛び先URLの設定</h4>
          <el-input v-model="format.link">
            <template slot="prepend">飛び先URL</template>
          </el-input>
          <div class="question-flex">
            <el-select
              v-model="format.blank"
              placeholder="選択してください。"
              style="margin-top: 15px; width: 400px"
            >
              <el-option
                v-for="item in blank"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <Question
              :placement="'right'"
              :content="`${toolData.name}からの飛び先URLを新しいウィンドウで開くようにしたい場合は、<br>「${toolData.name}からの飛び先URLを新規タブで開く」を選択してください。<br>現在のウィンドウのまま表示したい場合は、<br>「${toolData.name}からの飛び先URLを新規タブで開く」を選択してください。`"
            />
          </div>
        </el-col>
        <el-col
          v-show="
            (ABTestConfigData.sections >= 2 && index == 1) ||
            (ABTestConfigData.sections >= 3 && index == 3)
          "
          :span="24"
          class="partition"
        >
          <h4>表示分割位置の設定</h4>
          <el-row v-if="index == 1">
            <el-col>
              <div class="question-flex">
                <Question
                  :placement="'left'"
                  :content="'表示を変更する分割位置を指定するタグです。<br>分割領域1と分割領域2を分けるタグです。<br>当タグを設置した上側が分割領域1、下側が分割領域2となります。'"
                />
                <el-input v-model="partition01" onclick="this.select();">
                  <template slot="prepend">表示分割位置の仕切りタグ１</template>
                </el-input>
              </div>
            </el-col>
            <el-col>
              <div class="question-flex">
                <Question
                  :placement="'left'"
                  :content="'表示分割位置の仕切りタグ1を設置した場所など、任意でメモを残すことができます。'"
                />
                <el-input v-model="ABTestConfigData.partitionMemo01">
                  <template slot="prepend"
                    >表示分割位置の仕切りタグメモ１</template
                  >
                </el-input>
              </div>
              <div class="hr"></div>
            </el-col>
          </el-row>
          <el-row v-else-if="index == 3">
            <el-col>
              <div class="question-flex">
                <Question
                  :placement="'left'"
                  :content="'表示を変更する分割位置を指定するタグです。<br>分割領域2と分割領域3を分けるタグです。<br>当タグを設置した上側が分割領域2、下側が分割領域3なります。'"
                />
                <el-input v-model="partition01" onclick="this.select();">
                  <template slot="prepend">表示分割位置の仕切りタグ２</template>
                </el-input>
              </div>
            </el-col>
            <el-col>
              <div class="question-flex">
                <Question
                  :placement="'left'"
                  :content="'表示分割位置の仕切りタグ2を設置した場所など、任意でメモを残すことができます。'"
                />
                <el-input v-model="ABTestConfigData.partitionMemo01">
                  <template slot="prepend"
                    >表示分割位置の仕切りタグメモ２</template
                  >
                </el-input>
              </div>
              <div class="hr"></div>
            </el-col>
          </el-row>
        </el-col>
      </div>
    </el-row>
    <!-- ↑フォーマット登録部分↑ -->
    <el-row v-show="ABTestConfigData.sections >= 2">
      <el-col>
        <p>
          ※{{ toolData.name }}の表示分割機能を使用する場合、{{
            toolData.name
          }}を設定するすべてのページに上記の仕切りタグを設置する必要があります。
        </p>
      </el-col>
    </el-row>

    <!-- ↓登録ボタン↓ -->
    <el-row
      type="flex"
      justify="center"
      style="margin-top: 50px; margin-bottom: 50px"
    >
      <el-col :span="4" style="text-align: center">
        <el-button @click="reg" type="primary">登録</el-button>
      </el-col>
    </el-row>
    <!-- ↑登録ボタン↑ -->

    <el-row
      v-show="toolData.code2 == 'popup01'"
      type="flex"
      justify="center"
      style="margin-bottom: 100px"
    >
      <el-col :span="20">
        <h3 class="back-gray">ツール設置タグ</h3>
        <p style="color: red">
          ※以下のツール設置タグは、通常時のタグと同じものです。そのため、すでにタグを設置済の場合は、タグを張り替える必要はありません。
        </p>
        <p>
          {{ toolData.name }}を登場させたい位置に以下のタグを設置してください。
        </p>
        <el-input
          type="textarea"
          :autosize="{ minRows: 3 }"
          v-model="js_code[0]"
          onclick="this.select();"
        ></el-input>
        <!-- <p>jqueryを使用している場合、以下のタグを使用してください。</p>
        <el-input type="textarea" :autosize="{ minRows: 3}" v-model="js_code[1]" onclick="this.select();"></el-input> -->
      </el-col>
    </el-row>

    <!-- ↓フォーマット一覧↓ -->
    <FormatList
      :type="2"
      :toolId="toolId"
      :pageId="pageId"
      :buttonName="'編集'"
      @formatDecide="formatDecide2"
    />
  </div>
  <!-- ↑フォーマット一覧↑ -->
</template>

<script>
import { mapGetters } from "vuex";
import FormatRegister from "@/components/Ui/Tools01/FormatRegister.vue";
import FormatList from "@/components/Ui/Tools01/FormatList.vue";
import Question from "@/components/Ui/Question.vue";

export default {
  components: {
    Question,
    FormatRegister,
    // UrlConfig,
    FormatList,
  },
  props: {
    toolId: String,
    pageId: String,
    testId: String,
  },
  data() {
    return {
      componentStart: false,
      ABTestConfigData: {},
      js_code: "",
      partition01: '<div id="adtasukaruPopup01Partition01"></div>',
      partition02: '<div id="adtasukaruPopup01Partition02"></div>',
      after_list: [
        { id: 0, name: "Aを配信する" },
        { id: 1, name: "配信を停止する" },
      ],
      split_list: [1, 2, 3],

      //target="_blank"
      blank: [],
      ABTestConfigDataIsSet: false,
      ABTestConfigDataIsEditing: false,
    };
  },
  computed: {
    ...mapGetters(["rt"]),
    toolData() {
      return this.$store.getters["toolData"](this.toolId);
    },
    pageData() {
      return this.$store.getters["tools01/pageDataBySomeIds"](
        this.toolId,
        this.pageId
      );
    },
    ABTestDetailInfo() {
      return this.$store.getters.ABTestDetailInfo;
    },
  },
  watch: {
    ABTestConfigData: {
      handler() {
        if (this.ABTestConfigDataIsSet === true) {
          this.ABTestConfigDataIsEditing = true;
        } else {
          //no action.
        }
      },
      deep: true,
    },
  },
  created() {
    let params = new URLSearchParams();
    params.append("tool", this.toolData.code2);
    params.append("page_id", this.pageId);
    params.append("test_id", this.testId);
    this.$axios
      .post(`${this.rt}/api/tools01/getTestDetail.php`, params)
      .then((res) => {
        console.log(res);
        this.ABTestConfigData = res.data.list;
        this.componentStart = true; //応急処置
        //おそらく、ABTestConfigDataがこのタイミングで決まっているので、
        //「Error in render: "TypeError: Cannot read property '0' of undefined"」
        //のエラーが出ている。
        //→storeに持たせる構造に変える必要あり？
      })
      .catch((err) => {
        console.log(err);
      });

    this.$axios
      .get(
        `${this.rt}/api/tools01/getCode.php?tool=${this.toolData.code2}&site_id=${this.pageId}`
      )
      .then((res) => {
        console.log(res);
        this.js_code = res.data.code;
        this.ABTestConfigDataIsSet = true;
      })
      .catch((err) => {
        console.log(err);
      });

    this.blank = [
      {
        value: 0,
        label: `${this.toolData.name}からの飛び先URLを同一タブで開く`,
      },
      {
        value: 1,
        label: `${this.toolData.name}からの飛び先URLを新規タブで開く`,
      },
    ];
  },
  methods: {
    formatDecide(area, format_id) {
      console.log(`area: ${area}`);
      console.log(`format_id: ${format_id}`);

      let params = new URLSearchParams();
      params.append("tool", this.toolData.code2);
      params.append("site_id", this.pageId);
      params.append("format_id", format_id);
      params.append("type", "ab"); //activeの上書き問題解消
      this.$axios
        .post(`${this.rt}/api/tools01/getFormatDetail.php`, params)
        .then((res) => {
          console.log(res);
          this.$set(
            this.ABTestConfigData.format[area],
            "formatDetail",
            res.data.list
          );
          this.ABTestConfigData.format[area].format_id = format_id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatDecide2(format_id) {
      this.$router.push(
        `/tools01/${this.toolData.code2}/format/${this.pageId}/${format_id}`
      );
    },
    report() {
      this.$router.push(
        `/tools01/${this.toolData.code2}/abtest-report/${this.pageId}/${this.testId}`
      );
    },
    del() {
      if (
        confirm(
          `「${this.ABTestConfigData.name}」を削除します。よろしいですか？`
        )
      ) {
        let params = new URLSearchParams();
        params.append("tool", this.toolData.code2);
        params.append("page_id", this.pageId);
        params.append("test_id", this.testId);
        this.$axios
          .post(`${this.rt}/api/tools01/delTest.php`, params)
          .then((res) => {
            console.log(res);
            this.$router.push(
              `/tools01/${this.toolData.code2}/abtest/${this.pageId}`
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    reg() {
      let params = new URLSearchParams();
      params.append("tool", this.toolData.code2);
      params.append("page_id", this.pageId);
      params.append("test_id", this.testId);
      params.append("item", JSON.stringify(this.ABTestConfigData));
      this.$axios
        .post(`${this.rt}/api/tools01/regTestDetail.php`, params)
        .then((res) => {
          console.log(res);
          if (res.data == "OK") {
            this.$store
              .dispatch("tools01/setAbTestList")
              .then((response) => {
                console.log(response);
                if (this.testId == "0") {
                  this.$router.push(
                    `/tools01/${this.toolData.code2}/abtest/${this.pageId}`
                  );
                } else {
                  location.reload();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            alert(res.data);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-descriptions) {
  .el-descriptions__body {
    table {
      tbody {
        tr {
          th {
            width: 23%;
          }
        }
      }
    }
  }
}
.question-flex {
  margin-top: 10px;
}
.partition {
  margin: 30px auto;
  .el-col + .el-col {
    margin-top: 10px;
  }
}
.hr {
  height: 1px;
  background-color: #ccc;
  margin-top: 80px;
  margin-bottom: 40px;
}
</style>
