<template>
  <div class="home">
    <el-row>
      <el-col>
        <HomeNewsList />
        <div class="hr"></div>
      </el-col>
    </el-row>
    <div v-if="
      userData.bankCode !== '' && userData.invoiceRegistrationNumber === ''
    " class="announce-invoice-registration">
      <span class="red">アフィリエイト報酬を受取るにはインボイス登録番号が必要です。</span><br />
      登録は<router-link to="/affiliate/bank-account">こちら</router-link>
    </div>
    <el-row :gutter="30" style="margin-top: 30px">
      <el-col>
        <p
          style="color: red;font-size: 20px;border: solid 2px #666;border-radius: 5px;display: inline-block;padding: 0.5em 1.5em;">
          5月1日に価格改定を予定しています（<a href="https://adtasukaru.notion.site/1c3b265a22718061821fffcd8a46f58a" target="_blank"
            rel="noopener noreferrer">詳細はこちら</a>）</p>
        <HomeToolList />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeNewsList from "@/components/Home/NewsList.vue";
import HomeToolList from "@/components/Home/ToolList.vue";

export default {
  components: {
    HomeNewsList,
    HomeToolList,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "userData", "apiErrorMessage"]),
  },
  created() {
    this.$axios
      .get(`${this.rt}/api/getMessages.php`)
      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          res.data.messageList.forEach((messageData) => {
            setTimeout(() => {
              this.showNotification(
                messageData.title,
                messageData.message,
                5000
              );
            }, 0);
          });
          return;
        } else {
          alert(this.apiErrorMessage);
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    showNotification(ttl, msg, drt) {
      this.$notify({
        title: ttl,
        message: msg,
        duration: drt,
        dangerouslyUseHTMLString: true,
        position: "bottom-right",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-col-24) {
  @media screen and (max-width: 768px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

:deep(.el-row) {
  @media screen and (max-width: 768px) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.hr {
  height: 1px;
  background-color: #4d4d4d;
}

.announce-invoice-registration {
  border: solid 1px #666;
  display: inline-block;
  padding: 15px;
}
</style>